/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */

export const WISHLIST_STATE = {
	DEFAULT: 'Default',
	ACTIVE: 'Active',
	SAVE_FOR_LATER: 'SaveForLater',
};

export const DEFAULT_WISHLIST_PRODUCT_QUANTITY = '1';

export const WISHLIST_PAGE_SIZE = 4;

export const WISHLIST_DETAILS_PAGE_SIZE = 3;
